<template>
  <div class="container-header-rubric">
    <!-- Title Rubric -->
    <div class="title-rubric">
      <div class="d-flex justify-content-center">
        <div class="title-rubric">
          {{ rubric.title }}
        </div>
        <div class="align-self-center ml-2" v-if="allows_crud">
          <b-button
            v-if="
              !rubric.is_locked &&
              allows_crud &&
              user &&
              !user.groups.includes(6)
            "
            variant="none"
            size="sm"
            class="lock-btn mb-1 p-0"
            v-b-tooltip.top.noninteractive.v-secondary="
              `Bloquear  ${$getVisibleNames(
                'evaluations2.rubric',
                false,
                'Pauta'
              )}`
            "
            @click="changeStateRubric()"
          >
            <b-icon-unlock-fill></b-icon-unlock-fill>
          </b-button>
          <b-button
            v-if="
              rubric.is_locked &&
              allows_crud &&
              user &&
              !user.groups.includes(6)
            "
            variant="none"
            size="sm"
            class="lock-btn p-0 mb-1"
            v-b-tooltip.top.noninteractive.v-secondary="
              `Desbloquear  ${$getVisibleNames(
                'evaluations2.rubric',
                false,
                'Pauta'
              )}`
            "
            @click="changeStateRubric()"
          >
            <b-icon-lock-fill></b-icon-lock-fill>
          </b-button>
          <b-button
            v-if="allows_crud && !rubric.is_locked && allow_to_copy_rubric"
            variant="none"
            size="sm"
            class="edit-btn mr-1 p-0"
            @click="
              $bvModal.show(
                `modal-copy-rubric-${instrument_id}-${rubric.id}-${container_instrument_id}`
              )
            "
            v-b-tooltip.top.noninteractive.v-secondary="
              `Copiar  ${$getVisibleNames(
                'evaluations2.rubric',
                false,
                'Pauta'
              )}`
            "
          >
            <b-icon icon="files"></b-icon>
          </b-button>
          <b-modal
            :id="`modal-copy-rubric-${instrument_id}-${rubric.id}-${container_instrument_id}`"
            :title="`Copiar ${$getVisibleNames(
              'evaluations2.rubric',
              false,
              'Pauta'
            )}`"
            size="lg"
            @hide="
              () => {
                selected_copy_rubric_test_id = null;
              }
            "
            hide-footer
          >
            <b-form-group
              :label="`Seleccione donde se aplicará la copia:`"
              label-for="selected_copy_rubric_test_id"
              label-cols="0"
              label-cols-sm="auto"
              label-cols-md="auto"
              class="m-0 p-0 mb-2"
            >
              <b-form-select
                id="selected_copy_rubric_test_id"
                v-model="selected_copy_rubric_test_id"
                :options="testsWithoutRubric"
                value-field="id"
                text-field="title"
                class="mt-1"
                aria-describedby="selected_copy_rubric_test_id-feedback"
                size="sm"
              ></b-form-select>
            </b-form-group>
            <div class="d-flex">
              <b-button
                class="ml-auto mr-0"
                size="sm"
                @click="copyRubric"
                :disabled="selected_copy_rubric_test_id == null"
                >Copiar Rubrica</b-button
              >
            </div>
          </b-modal>
          <button-edit
            v-if="allows_crud && !rubric.is_locked"
            class="edit-btn action-button"
            @click="
              $bvModal.show(
                `modal-update-rubric-${instrument_id}-${rubric.id}-${container_instrument_id}`
              )
            "
            v-b-tooltip.top.noninteractive.v-secondary="
              `Editar ${$getVisibleNames(
                'evaluations2.rubric',
                false,
                'Pauta'
              )}`
            "
          ></button-edit>
          <b-modal
            :id="`modal-update-rubric-${instrument_id}-${rubric.id}-${container_instrument_id}`"
            :title="`Editar ${$getVisibleNames(
              'evaluations2.rubric',
              false,
              'Pauta'
            )}`"
            size="lg"
            hide-footer
            no-enforce-focus
          >
            <NewRubricForm
              :Rubric="rubric"
              :test_id="instrument_id"
              @reset_rubric_coevaluators="$emit('reset_rubric_coevaluators')"
              @closeModal="
                $bvModal.hide(
                  `modal-update-rubric-${instrument_id}-${rubric.id}-${container_instrument_id}`
                )
              "
            ></NewRubricForm>
          </b-modal>
          <b-button
            class="btn-delete-option p-0"
            v-if="allows_crud && !rubric.is_locked"
            v-b-tooltip.top.noninteractive.v-secondary="
              `Eliminar ${$getVisibleNames(
                'evaluations2.rubric',
                false,
                'Pauta'
              )}`
            "
            @click="askForDeteleRubric()"
            variant="none"
          >
            <b-icon-trash></b-icon-trash>
          </b-button>
        </div>
      </div>
    </div>
    <!-- Description Rubric -->
    <div
      v-html="rubric.description"
      class="description-rubric rich-text-content"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { toast } from "@/utils/utils";

export default {
  name: "HeaderRubric",
  components: {
    NewRubricForm: () =>
      import("@/components/new-evaluations/Rubrics/NewRubricForm"),
  },
  props: {
    rubric_id: {
      required: true,
      type: Number,
    },
    container_instrument_id: {
      type: String,
    },
    instrument_id: {
      required: true,
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selected_copy_rubric_test_id: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      evaluations: names.NEW_EVALUATIONS,
      tests: names.NEW_TESTS,
    }),
    rubric() {
      return this.rubrics.find((x) => x.id == this.rubric_id);
    },
    test() {
      return this.tests.find((x) => x.id == this.instrument_id);
    },
    evaluation() {
      if (!this.test) return null;
      return this.evaluations.find((x) => x.id == this.test.evaluation);
    },
    testsFilter() {
      if (!this.evaluation) return [];
      return this.tests.filter((x) => x.evaluation == this.evaluation.id);
    },
    rubricsFilter() {
      const tests_ids = this.testsFilter.map((x) => x.id);
      return this.rubrics.filter((x) => tests_ids.includes(x.test));
    },
    testsWithoutRubric() {
      const test_ids = this.rubricsFilter.map((x) => x.test);
      return this.testsFilter.filter((x) => !test_ids.includes(x.id));
    },
    allow_to_copy_rubric() {
      if (!this.evaluation) return false;
      if (this.rubricsFilter.length < this.testsFilter.length) return true;
      else return false;
    },
  },
  methods: {
    copyRubric() {
      this.$restful
        .Get(
          `/evaluations2/copy_rubric_in_test/?rubric=${this.rubric_id}&test=${this.selected_copy_rubric_test_id}`
        )
        .then((rubric_id) => {
          this.$store.dispatch(names.FETCH_NEW_RUBRIC_INSTRUMENT, rubric_id);
          this.$bvModal.hide(
            `modal-copy-rubric-${this.instrument_id}-${this.rubric.id}-${this.container_instrument_id}`
          );
          this.selected_copy_rubric_test_id = null;
        });
    },

    changeStateRubric() {
      const payload = {
        rubric_instrument_id: this.rubric.id,
        item: {
          is_locked: !this.rubric.is_locked,
        },
      };
      this.$store.dispatch(names.PATCH_NEW_RUBRIC_INSTRUMENT, payload);
    },
    askForDeteleRubric() {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar la ${this.$getVisibleNames(
          "evaluations2.rubric",
          false,
          "Pauta"
        )}?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_NEW_RUBRIC_INSTRUMENT, this.rubric.id)
            .then(() => {
              toast(
                "Se eliminó la " +
                  this.$getVisibleNames("evaluations2.rubric", false, "Pauta")
              );
            });
        }
      });
    },
  },
  mounted() {},
  created() {},
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scope>
.container-buttons-allow-cruds {
  display: flex;
  padding: 5px;
  justify-content: right;
}
.lock-btn {
  color: white;
  border: 1px;
  max-height: 27px;
  width: 27px;
}
.lock-btn:hover {
  color: white;
  border: none;
}
.container-header-rubric {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin-bottom: 1rem;
}
.title-rubric {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 18pt;
  color: white;
  font-weight: bold;
  background-color: var(--kl-menu-color) !important;
}
.description-rubric {
  text-align: left;
  margin: 7px;
  font-size: 10pt;
}
.edit-btn {
  color: white;
  border: none;
  padding: 0px 0px 0 1px;
}
.edit-btn:hover {
  color: white;
  border: none;
}
.btn-delete-option {
  background: transparent;
  color: white;
  border: none;
}
.btn-delete-option:hover {
  background: transparent;
  color: white;
  border: none;
}
.action-button {
  border: none;
  width: 25px;
  height: 25px;
  margin-inline: 3px;
  border-radius: 5px;
}
</style>